// var app = document.getElementById("app");

// var typewriter = new Typewriter(app, {
//   loop: true,
//   delay: 75,
// });

// typewriter
//   .pauseFor(2500)
//   .typeString("A simple yet powerful native javascript")
//   .pauseFor(300)
//   .deleteChars(10)
//   .typeString("<strong>JS</strong> plugin for a cool typewriter effect and ")
//   .typeString(
//     '<strong>only <span style="color: #27ae60;">5kb</span> Gzipped!</strong>'
//   )
//   .pauseFor(1000)
//   .start();

function main() {
  const els = document.querySelectorAll("[typeable]");
  const tasks = [];
  els.forEach((el) => {
    const text = el.innerHTML;
    tasks.push([el, text.split("\n").join(" ")]);
    el.innerHTML = "";
  });

  let i = 0;
  function cb() {
    i += 1;
    if (i >= tasks.length) return;
    type(tasks[i], cb);
  }

  type(tasks[i], cb);
}

function type([el, text], cb) {
  var typewriter = new Typewriter(el, {
    loop: false,
    delay: 75,
  });

  typewriter
    // .pauseFor(2500)
    .typeString(text)
    // .pauseFor(1000)
    .callFunction(() => {
      document.querySelectorAll(".Typewriter__cursor").forEach((n) => {
        n.innerHTML = "";
      });
      cb();
    })
    .stop()
    .start();
}

main();
